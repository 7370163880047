// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-elements-accordion-js": () => import("./../../../src/pages/elements/accordion.js" /* webpackChunkName: "component---src-pages-elements-accordion-js" */),
  "component---src-pages-elements-box-icon-js": () => import("./../../../src/pages/elements/box-icon.js" /* webpackChunkName: "component---src-pages-elements-box-icon-js" */),
  "component---src-pages-elements-box-image-js": () => import("./../../../src/pages/elements/box-image.js" /* webpackChunkName: "component---src-pages-elements-box-image-js" */),
  "component---src-pages-elements-box-large-image-js": () => import("./../../../src/pages/elements/box-large-image.js" /* webpackChunkName: "component---src-pages-elements-box-large-image-js" */),
  "component---src-pages-elements-button-js": () => import("./../../../src/pages/elements/button.js" /* webpackChunkName: "component---src-pages-elements-button-js" */),
  "component---src-pages-elements-call-to-action-js": () => import("./../../../src/pages/elements/call-to-action.js" /* webpackChunkName: "component---src-pages-elements-call-to-action-js" */),
  "component---src-pages-elements-client-logo-js": () => import("./../../../src/pages/elements/client-logo.js" /* webpackChunkName: "component---src-pages-elements-client-logo-js" */),
  "component---src-pages-elements-counters-js": () => import("./../../../src/pages/elements/counters.js" /* webpackChunkName: "component---src-pages-elements-counters-js" */),
  "component---src-pages-elements-dividers-js": () => import("./../../../src/pages/elements/dividers.js" /* webpackChunkName: "component---src-pages-elements-dividers-js" */),
  "component---src-pages-elements-google-map-js": () => import("./../../../src/pages/elements/google-map.js" /* webpackChunkName: "component---src-pages-elements-google-map-js" */),
  "component---src-pages-elements-gradation-js": () => import("./../../../src/pages/elements/gradation.js" /* webpackChunkName: "component---src-pages-elements-gradation-js" */),
  "component---src-pages-elements-lists-js": () => import("./../../../src/pages/elements/lists.js" /* webpackChunkName: "component---src-pages-elements-lists-js" */),
  "component---src-pages-elements-message-box-js": () => import("./../../../src/pages/elements/message-box.js" /* webpackChunkName: "component---src-pages-elements-message-box-js" */),
  "component---src-pages-elements-popup-video-js": () => import("./../../../src/pages/elements/popup-video.js" /* webpackChunkName: "component---src-pages-elements-popup-video-js" */),
  "component---src-pages-elements-pricing-box-js": () => import("./../../../src/pages/elements/pricing-box.js" /* webpackChunkName: "component---src-pages-elements-pricing-box-js" */),
  "component---src-pages-elements-progress-bar-js": () => import("./../../../src/pages/elements/progress-bar.js" /* webpackChunkName: "component---src-pages-elements-progress-bar-js" */),
  "component---src-pages-elements-progress-circle-js": () => import("./../../../src/pages/elements/progress-circle.js" /* webpackChunkName: "component---src-pages-elements-progress-circle-js" */),
  "component---src-pages-elements-social-networks-js": () => import("./../../../src/pages/elements/social-networks.js" /* webpackChunkName: "component---src-pages-elements-social-networks-js" */),
  "component---src-pages-elements-tabs-js": () => import("./../../../src/pages/elements/tabs.js" /* webpackChunkName: "component---src-pages-elements-tabs-js" */),
  "component---src-pages-elements-testimonials-js": () => import("./../../../src/pages/elements/testimonials.js" /* webpackChunkName: "component---src-pages-elements-testimonials-js" */),
  "component---src-pages-elements-timeline-js": () => import("./../../../src/pages/elements/timeline.js" /* webpackChunkName: "component---src-pages-elements-timeline-js" */),
  "component---src-pages-elements-typed-text-js": () => import("./../../../src/pages/elements/typed-text.js" /* webpackChunkName: "component---src-pages-elements-typed-text-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-appointment-js": () => import("./../../../src/pages/index-appointment.js" /* webpackChunkName: "component---src-pages-index-appointment-js" */),
  "component---src-pages-index-cybersecurity-js": () => import("./../../../src/pages/index-cybersecurity.js" /* webpackChunkName: "component---src-pages-index-cybersecurity-js" */),
  "component---src-pages-index-infotechno-js": () => import("./../../../src/pages/index-infotechno.js" /* webpackChunkName: "component---src-pages-index-infotechno-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-processing-js": () => import("./../../../src/pages/index-processing.js" /* webpackChunkName: "component---src-pages-index-processing-js" */),
  "component---src-pages-index-resolutions-js": () => import("./../../../src/pages/index-resolutions.js" /* webpackChunkName: "component---src-pages-index-resolutions-js" */),
  "component---src-pages-index-services-js": () => import("./../../../src/pages/index-services.js" /* webpackChunkName: "component---src-pages-index-services-js" */),
  "component---src-pages-industrial-research-js": () => import("./../../../src/pages/industrial-research.js" /* webpackChunkName: "component---src-pages-industrial-research-js" */),
  "component---src-pages-internal-album-js": () => import("./../../../src/pages/internal/album.js" /* webpackChunkName: "component---src-pages-internal-album-js" */),
  "component---src-pages-internal-calendar-js": () => import("./../../../src/pages/internal/calendar.js" /* webpackChunkName: "component---src-pages-internal-calendar-js" */),
  "component---src-pages-internal-resources-js": () => import("./../../../src/pages/internal/resources.js" /* webpackChunkName: "component---src-pages-internal-resources-js" */),
  "component---src-pages-it-solutions-js": () => import("./../../../src/pages/it-solutions.js" /* webpackChunkName: "component---src-pages-it-solutions-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-members-baobaosong-js": () => import("./../../../src/pages/members/baobaosong.js" /* webpackChunkName: "component---src-pages-members-baobaosong-js" */),
  "component---src-pages-members-gangli-js": () => import("./../../../src/pages/members/gangli.js" /* webpackChunkName: "component---src-pages-members-gangli-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-open-code-js": () => import("./../../../src/pages/open-code.js" /* webpackChunkName: "component---src-pages-open-code-js" */),
  "component---src-pages-open-data-js": () => import("./../../../src/pages/open-data.js" /* webpackChunkName: "component---src-pages-open-data-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-pricing-plan-js": () => import("./../../../src/pages/pricing-plan.js" /* webpackChunkName: "component---src-pages-pricing-plan-js" */),
  "component---src-pages-publication-ai-js": () => import("./../../../src/pages/publication/ai.js" /* webpackChunkName: "component---src-pages-publication-ai-js" */),
  "component---src-pages-publication-app-js": () => import("./../../../src/pages/publication/app.js" /* webpackChunkName: "component---src-pages-publication-app-js" */),
  "component---src-pages-publication-bi-js": () => import("./../../../src/pages/publication/bi.js" /* webpackChunkName: "component---src-pages-publication-bi-js" */),
  "component---src-pages-publication-conferences-js": () => import("./../../../src/pages/publication/conferences.js" /* webpackChunkName: "component---src-pages-publication-conferences-js" */),
  "component---src-pages-publication-journals-js": () => import("./../../../src/pages/publication/journals.js" /* webpackChunkName: "component---src-pages-publication-journals-js" */),
  "component---src-pages-publication-js": () => import("./../../../src/pages/publication.js" /* webpackChunkName: "component---src-pages-publication-js" */),
  "component---src-pages-publication-monograph-js": () => import("./../../../src/pages/publication/monograph.js" /* webpackChunkName: "component---src-pages-publication-monograph-js" */),
  "component---src-pages-publication-proceedings-js": () => import("./../../../src/pages/publication/proceedings.js" /* webpackChunkName: "component---src-pages-publication-proceedings-js" */),
  "component---src-pages-publication-ps-js": () => import("./../../../src/pages/publication/ps.js" /* webpackChunkName: "component---src-pages-publication-ps-js" */),
  "component---src-pages-research-activities-js": () => import("./../../../src/pages/research-activities.js" /* webpackChunkName: "component---src-pages-research-activities-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-research-training-js": () => import("./../../../src/pages/research-training.js" /* webpackChunkName: "component---src-pages-research-training-js" */),
  "component---src-pages-tutorials-anomaly-detection-ecml-2023-js": () => import("./../../../src/pages/tutorials/anomaly-detection/ECML2023.js" /* webpackChunkName: "component---src-pages-tutorials-anomaly-detection-ecml-2023-js" */),
  "component---src-pages-tutorials-ganblr-icdm-2022-js": () => import("./../../../src/pages/tutorials/ganblr/ICDM2022.js" /* webpackChunkName: "component---src-pages-tutorials-ganblr-icdm-2022-js" */),
  "component---src-pages-tutorials-ganblr-pakdd-2021-js": () => import("./../../../src/pages/tutorials/ganblr/PAKDD2021.js" /* webpackChunkName: "component---src-pages-tutorials-ganblr-pakdd-2021-js" */),
  "component---src-pages-tutorials-js": () => import("./../../../src/pages/tutorials.js" /* webpackChunkName: "component---src-pages-tutorials-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */),
  "component---src-templates-author-template-author-template-js": () => import("./../../../src/templates/author-template/author-template.js" /* webpackChunkName: "component---src-templates-author-template-author-template-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-case-study-template-case-study-template-js": () => import("./../../../src/templates/case-study-template/case-study-template.js" /* webpackChunkName: "component---src-templates-case-study-template-case-study-template-js" */),
  "component---src-templates-cate-template-cate-template-js": () => import("./../../../src/templates/cate-template/cate-template.js" /* webpackChunkName: "component---src-templates-cate-template-cate-template-js" */),
  "component---src-templates-category-template-category-template-js": () => import("./../../../src/templates/category-template/category-template.js" /* webpackChunkName: "component---src-templates-category-template-category-template-js" */),
  "component---src-templates-date-template-date-template-js": () => import("./../../../src/templates/date-template/date-template.js" /* webpackChunkName: "component---src-templates-date-template-date-template-js" */),
  "component---src-templates-it-solution-template-it-solution-template-js": () => import("./../../../src/templates/it-solution-template/it-solution-template.js" /* webpackChunkName: "component---src-templates-it-solution-template-it-solution-template-js" */),
  "component---src-templates-news-template-news-template-js": () => import("./../../../src/templates/news-template/news-template.js" /* webpackChunkName: "component---src-templates-news-template-news-template-js" */),
  "component---src-templates-search-template-search-template-js": () => import("./../../../src/templates/search-template/search-template.js" /* webpackChunkName: "component---src-templates-search-template-search-template-js" */),
  "component---src-templates-service-template-service-template-js": () => import("./../../../src/templates/service-template/service-template.js" /* webpackChunkName: "component---src-templates-service-template-service-template-js" */),
  "component---src-templates-tag-template-tag-template-js": () => import("./../../../src/templates/tag-template/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-tag-template-js" */)
}

